.discountText,
.successText {
  margin-bottom: 20px;
  font-size: 32px;
  line-height: 48px;

  b {
    font-size: inherit;
  }
}

.discountSubText {
  font-size: 22px;
  line-height: 32px;
  margin-bottom: 30px;

  b {
    font-size: inherit;
  }
}

.successSubText {
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: normal;
}

.continue {
  width: 100%;
}

@media screen and (max-width: 768px) {
  .successText,
  .discountText {
    font-size: 24px;
  }

  .continue {
    min-height: auto;
  }

  .sitePitchModal {
    .discountText, .successText {
      line-height: normal;
      margin-bottom: 10px;
    }

    p {
      font-size: 18px;
      line-height: normal;
    }
  }
}
