html,
body {
  overscroll-behavior: none;
}

.max-width-600 {
  width: 100%;
  max-width: 600px;
}

.subTitle {
  margin: 0 0 40px;
  word-break: break-word;
}

.closeIcon {
  display: flex;
  position: absolute;
  top: 20px;
  right: 20px;
  width: 20px;
  height: 20px;
  padding: 0;
  border: none;
  background: transparent;
  cursor: pointer;
  z-index: 2;

  &::after {
    content: "";
    height: 20px;
    border-left: 3px solid #888888;
    position: absolute;
    transform: rotate(45deg);
    left: 9px;
  }

  &::before {
    content: "";
    height: 20px;
    border-left: 3px solid #888888;
    position: absolute;
    transform: rotate(-45deg);
    left: 9px;
  }

  &:hover {
    &::after {
      border-left: 3px solid #000;
    }

    &::before {
      border-left: 3px solid #000;
    }
  }
}

.spinner {
  display: inline-block;
  border: 4px solid #0066ff;
  border-top: 4px solid transparent;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  margin-bottom: 20px;
  animation: rotation linear 1500ms infinite;
}

.tooltip {
  color: #000 !important;
  max-width: 300px;
  background-color: #fff8d0 !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12) !important;
  opacity: 1 !important;
  text-align: left;

  &:after {
    background-color: #fff8d0 !important;
    border: 1px solid #b4b4b4 !important;
  }

  &:first-letter {
    text-transform: uppercase;
  }

  div {
    font-size: 20px;
    font-weight: 500;
    color: #000;
    margin-bottom: 8px;
  }

  strong {
    font-size: inherit !important;
  }
}

.colorGreen {
  color: #1a874d;
}

@keyframes rotation {
  100% {
    transform: rotate(360deg);
  }
}

.ant-modal-body {
  max-height: calc(var(--app-height) - 100px);
  overflow-y: auto; // allow scrolling inside the modal
}

.ant-input {
  border-color: var(--dark-border-color);
  border-radius: 8px;
}

@media screen and (min-width: 768px) {
  *::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  *::-webkit-scrollbar-track {
    background: transparent;
  }

  *:hover::-webkit-scrollbar-thumb {
    background: rgba(136, 136, 136, 0.3);
    border-radius: 4px;
  }
}

@media screen and (max-width: 640px) {
  .subTitle {
    margin: 0 0 24px;
    justify-content: center;
  }
}
