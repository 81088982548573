.modal {
  display: flex;
  flex-direction: row;
  background-color: white;
  border-radius: 8px;
  max-width: 950px;
  margin: auto;
  padding: 0;
  position: relative;
  overflow: hidden;
}

.overlay {
  background-color: rgba(0, 0, 0, 0.75);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modalContent {
  display: flex;
  width: 100%;
  position: relative;
}

.leftContainer,
.rightContainer {
  flex: 1;
  flex-basis: 600px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.rightContainer {
  padding: 40px;
  position: relative;
  align-self: center;
  flex-basis: 600px;
}

.closeButton {
  position: absolute;
  top: 20px;
  right: 16px;
  cursor: pointer;
  background: none;
  border: none;
  z-index: 10;
  min-width: 0;
  min-height: 0;
  padding: 0;
  font-weight: normal;
}

.closeIcon {
  width: 24px;

  path {
    fill: var(--secondary-text);
  }
}

@media screen and (max-width: 768px) {
  .modal {
    flex-direction: column;
    margin: unset;
    align-self: flex-end;
    border-radius: 0;
    // width: 100vw;
  }

  .modalContent {
    flex-direction: column;
  }

  .leftContainer,
  .rightContainer {
    flex-basis: auto;
  }

  .rightContainer {
    padding: 20px;
  }

  .closeButton {
    display: flex;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.72);
    align-items: center;
  }

  .closeIcon {
    width: 36px;
    height: 36px;
    path {
      fill: var(--black);
    }
  }
}
