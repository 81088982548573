.loaderContainer {
  width: 100vw;
  height: var(--app-height);
  background: #ffffff;
}

.loader {
  width: 50px;
  height: 50px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}
