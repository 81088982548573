html {
  --primary: #0066ff;
  --success: #17B87E;
  --separator: #dedede;
  --heading-text: #000;
  --highlighted-text: #2170f4;

  --cp-link: var(--highlighted-text);
  --back-button: var(--highlighted-text);
  --setup-complete-success-text: var(--success);
  --setup-dns-text-separator: var(--separator);
  --setup-complete-separator: var(--separator);

  --user-profile-bg: var(--primary);
  --user-profile-text: #fff;
  --user-profile-dropdown-icon-border: #000;
  --user-profile-signin-btn-border: #24273d;
  --user-profile-dropdown-bg: #fff;
  --user-profile-dropdown-overlay-border: #e2e2e2;
  --user-profile-dropdown-menu-separator: var(--separator);
  --user-profile-dropdown-menu-hover: #deefff;

  --copy-text-content: #333;
  --copy-text-background: #fcf6cd;
  --copy-url-border-color: #d6c5a6;
  --copy-url-text: var(--highlighted-text);

  --loader-text-color: #333;
  --site-setup-text: #333;
}