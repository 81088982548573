* {
  font-size: 16px;
  color: #333333;
  margin: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

body {
  height: 100%;
  background: #fff;
}

label {
  display: block;
  margin-bottom: 16px;
  font-size: 18px;
  font-weight: 500;
}

strong {
  font-weight: 600;
}

//custom toast notification styles

.toast-notification {
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  width: 100% !important;
  max-width: min(80%, 500px) !important;

  .Toastify__toast {
    border-radius: 10px;
    background: #333;

    .Toastify__toast-body div {
      color: #fff !important;
    }
  }
}

/* Poppins-extra-light - latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 200;
  src: local(""), url("../../fonts/Poppins-ExtraLight.ttf") format("truetype");
}

/* Poppins-extra-light-italic - latin */
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 400;
  src: local(""),
    url("../../fonts/Poppins-ExtraLightItalic.ttf") format("truetype");
}

/* Poppins-regular - latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  src: local(""), url("../../fonts/Poppins-Regular.ttf") format("truetype");
}

/* Poppins-italic - latin */
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 400;
  src: local(""), url("../../fonts/Poppins-Italic.ttf") format("truetype");
}

/* Poppins-Medium - latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  src: local(""), url("../../fonts/Poppins-Medium.ttf") format("truetype");
}

/* Poppins-MediumItalic - latin */
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 600;
  src: local(""), url("../../fonts/Poppins-MediumItalic.ttf") format("truetype");
}

/* Poppins-SemiBold - latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  src: local(""), url("../../fonts/Poppins-SemiBold.ttf") format("truetype");
}

/* Poppins-SemiBoldItalic - latin */
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 600;
  src: local(""),
    url("../../fonts/Poppins-SemiBoldItalic.ttf") format("truetype");
}

/* Poppins-Bold - latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  src: local(""), url("../../fonts/Poppins-Bold.ttf") format("truetype");
}

/* Poppins-700italic - latin */
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 700;
  src: local(""), url("../../fonts/Poppins-BoldItalic.ttf") format("truetype");
}

.toast-position {
  top: 10rem !important;
}

@media screen and (max-width: 640px) {
  * {
    font-size: 15px;
  }

  h1 {
    font-size: 22px;
    font-weight: 600;
  }
}
