// This page will be shown to mobile and tablet not for the desktop

.congrats {
  font-weight: 600;
  font-size: 32px;
  margin: 0 0 12px;
}

.coSiteSetup {
  margin-bottom: 32px;
}

.neoSpaceUrl {
  display: flex;
  align-items: center;
  background: #fcf6cd;
  border-radius: 4px;
  font-weight: 600;
  margin: 12px 0 30px;
  max-width: 100%;
  width: 400px;
  word-break: break-all;

  :first-child {
    width: 100%;
    padding: 16px;
    border-right: 1px solid #dedede;
  }

  :last-child {
    padding: 16px;
    color: #2170f4;
    white-space: nowrap;
    cursor: pointer;
  }
}

.horizontalLine {
  margin: 0 0 30px;
  border: 1px solid #dedede;
}
