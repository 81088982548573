.container {
  width: 100%;
  max-width: 1240px;
  padding: 10px 30px 30px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  position: relative;

  .logo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto 30px;
    width: 100%;

    img {
      cursor: pointer;
      width: 34px;
    }
  }

  .content {
    display: flex;
    justify-content: center;
    //margin-bottom: 20px;
    // margin: 0 auto 20px;
    // max-width: 960px;
    width: 100%;

    aside {
      flex: 40%;
      padding: 20px;
    }

    main {
      flex: 60%;
    }
  }
}

.version {
  position: fixed;
  bottom: 10px;
  right: 30px;
  font-size: 13px;
  color: #c7c7c7;
  opacity: 0.8;
  letter-spacing: 1px;
}

@media screen and (max-width: 640px) {
  .container {
    padding: 15px 15px 50px 15px;

    .content {
      display: block;

      aside,
      main {
        width: 100%;
      }
    }
  }

  .version {
    position: static;
    font-size: 11px;
    text-align: right;
    margin-bottom: 12px;
  }
}
