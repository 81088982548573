.freeDomainTitle,
.youWillHearFromUs {
  margin-bottom: 10px;
  font-size: 28px;
  font-weight: 600;
}

.successText {
  margin-bottom: 20px;
}

.freeDomainSubTitle {
  margin-bottom: 20px;
  font-weight: normal;
  b {
    font-size: inherit;
  }
}

.continue,
.getFreeDomainButton {
  width: 100%;
  font-weight: normal;
}

.remindMeLater {
  margin-top: 20px;
  font-weight: normal;
  color: var(--primary);
  cursor: pointer;
  text-align: center;
}

.congratsTitle {
  font-size: 28px;
  margin-bottom: 10px;
  b{
    font-size: inherit;
  }
}

.congratsSubtitle {
  font-weight: normal;
  margin-bottom: 20px;
}
