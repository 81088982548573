.buttonsWrapper {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin: 20px 0 0;

  > img {
    width: 48%;
  }

  :not(:last-child) {
    margin-bottom: unset;
  }
}

.strong {
  font-size: 16px;
}

@media screen and (min-width: 540px) {
  .buttonsWrapper {
    > img {
      width: unset;
    }
  }
}
