.backBtn {
  margin: 0 auto 24px;
  display: inline-flex;
  align-items: center;
  color: var(--back-button);
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  > img {
    width: 20px;
    margin-right: 4px;
  }
}
