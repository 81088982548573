.profileWrapper {
  position: relative;

  .userProfile {
    display: flex;
    align-items: center;
    cursor: pointer;

    .userIcon {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background: var(--user-profile-bg);
      color: var(--user-profile-text);
      width: 30px;
      aspect-ratio: 1;
    }

    .usernameWrapper {
      display: flex;
      align-items: center;
      font-weight: 600;
    }

    .username {
      margin-left: 8px;
    }

    .dropdownIcon {
      margin-left: 10px;
      width: 0;
      height: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 8px solid var(--user-profile-dropdown-icon-border);
      transition: transform 0.4s ease-in-out;

      &.open {
        transform: rotateX(180deg);
      }
    }
  }

  .signInButton {
    display: flex;
    align-items: center;
    border: 1px solid var(--user-profile-signin-btn-border);
    border-radius: 8px;
    font-weight: 700;
    padding: 8px 16px;
    cursor: pointer;
  }

  .dropdownList {
    position: absolute;
    right: 0;
    top: 58px;
    background: var(--user-profile-dropdown-bg);
    border-radius: 10px;
    border: 1px solid var(--user-profile-dropdown-overlay-border);
    box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.12);
    z-index: 1;
    padding: 7px 0;

    &:before {
      content: "";
      display: block;
      position: absolute;
      right: 46px;
      bottom: 100%;
      width: 0;
      height: 0;
      border: 10px solid transparent;
      border-bottom-color: rgba(0, 0, 0, 0.12);
    }

    &:after {
      content: "";
      display: block;
      position: absolute;
      right: 47px;
      bottom: 100%;
      width: 0;
      height: 0;
      border: 9px solid transparent;
      border-bottom-color: white;
    }

    .borderLine {
      border: 1px solid var(--user-profile-dropdown-menu-separator);
    }

    .dropdownItem {
      display: flex;
      align-items: center;
      padding: 14px 20px;
      white-space: nowrap;
      cursor: pointer;

      &:hover {
        background: var(--user-profile-dropdown-menu-hover);
      }

      > img {
        width: 20px;
        margin-right: 10px;
      }
    }

    .signedOutDropdownItem {
      display: flex;
      align-items: center;
      padding: 14px 20px;
      white-space: nowrap;
      cursor: pointer;

      &:hover {
        background: var(--user-profile-dropdown-menu-hover);
      }

      > img {
        width: 40px;
        margin-right: 10px;
      }

      > div {
        display: flex;
        flex-direction: column;
      }
    }
  }
}

@media screen and (max-width: 640px) {
  .profileWrapper {
    .dropdownList {
      .signedOutDropdownItem {
        padding: 10px;
        > img {
          width: 25px;
        }
      }

      &:before {
        right: 27px;
      }

      &:after {
        right: 28px;
      }
    }
  }
  .username {
    display: none;
  }
}
