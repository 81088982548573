.countryNotSupported {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 50px;
  position: absolute;
  left: 50%;
  top: 40%; // we don't want it to be exactly in the middle
  transform: translate(-50%, -50%);
  width: 100%;
  
  .title {
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 24px;
  }

  .knowMore {
    color: var(--primary-color);
    text-decoration: none;
    cursor: pointer;
  }
}

@media screen and (max-width: 640px) {
  .countryNotSupported {
    gap: 30px;
    top: 50%;

    .title {
      font-size: 24px;
      margin-bottom: 8px;
    }
  }
}
