.inputWrapper {
  margin-bottom: 16px;

  .input {
    padding: 12px 20px;
  }
}

.submitButton {
  width: 100%;
  padding: 12px 20px;
  font-weight: normal;
}

@media screen and (max-width: 768px) {
  .submitButton {
    min-height: auto;
  }
}