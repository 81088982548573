.footer {
  font-style: italic;
  margin-top: 48px;
  color: var(--ternary-text-color);
  font-size: 14px;
}

@media screen and (max-width: 768px) {
  .footer {
    margin-top: 12px;
  }
}