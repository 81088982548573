button {
  --maxWidth: 100%;
  --width: 100%;
  max-width: var(--maxWidth);
  width: var(--width);
}

.btn {
  display: inline-block;
  text-align: center;
  padding: 16px;
  border-radius: 6px;
  border: 0;
  font-weight: 500;
  cursor: pointer;
  min-height: 53px;
  min-width: 150px;
}

.primary,
.primary * {
  background: #0066ff;
  color: #ffffff;
}

.secondary {
  background: #ffffff;
  color: #0066ff;
  border: 1px solid #0066ff;
}

.processing {
  background-image: url("/assets/loader-white.svg");
  background-repeat: no-repeat;
  background-position: 10px 50%;
  opacity: 0.7;
  padding-left: 45px !important;
  //background: inherit url("/assets/loader-white.svg") ;
}

.disabled {
  opacity: 0.3;
  cursor: not-allowed;
}
