.dns-setup-complete {
  width: 100%;
  max-width: 500px;
  text-align: center;
  margin: 0 auto;

  &.setup-mode-site {
    max-width: 700px;
    color: var(--site-setup-text);
    text-align: center;

    h1 {
      margin: 0;
      font-size: 30px;
      padding-bottom: 44px;

      i {
        width: 22px;
        height: 22px;
        display: inline-block;
        background: url("../../assets/images/success-tick.svg") center;
        background-repeat: no-repeat;
        margin-left: 8px;
      }

      & + div {
        padding-top: 44px;
        border-top: 1px solid var(--separator);
      }
    }

    h3 {
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%;
      margin-bottom: 8px;
    }

    p {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;

      b {
        font-weight: 500;
      }
    }

    img {
      max-width: 482px;
      width: 100%;
    }

    button {
      width: 400px;
      margin-top: 40px;
      box-sizing: border-box;
    }
  }

  h1 {
    color: var(--heading-text);
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 50px;
    margin: 35px auto 20px;
  }

  a,
  span {
    color: var(--highlighted-text);
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    & + p {
      margin-top: 10px;
    }
  }

  p {
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 80px;

    button {
      width: 100%;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;

      & + button {
        margin-top: 32px;
      }
    }
  }

  .mobile-instructions {
    display: none;

    & > div {
      margin: 18px 0 46px;

      p {
        color: var(--copy-text-content);
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
      }

      .copy-url {
        display: flex;
        align-items: center;
        background: var(--copy-text-background);
        border-radius: 4px;
        font-weight: 600;
        margin: 12px 0 30px;
        max-width: 100%;
        width: 400px;
        word-break: break-all;

        :first-child {
          width: 100%;
          padding: 16px;
          border-right: 1px solid var(--copy-url-border-color);
        }

        :last-child {
          padding: 16px;
          color: var(--copy-url-text);
          white-space: nowrap;
          cursor: pointer;
        }
      }
    }
  }

  @media screen and (max-width: 640px) {
    text-align: left;

    & > img {
      display: none;
    }

    h1 {
      color: var(--setup-complete-success-text);
      font-size: 32px;
      line-height: 44px;
      margin-bottom: 7px;
    }

    p {
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
    }

    a,
    span {
      font-size: 20px;
      text-decoration: none;
    }

    .actions {
      display: none;
    }

    .mobile-instructions {
      display: block;
      position: relative;
      margin: 60px 0 50px;

      &::before {
        content: '';
        position: absolute;
        top: -30px;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: var(--setup-complete-separator);
      }
    }
  }
}