.loader {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;

  img {
    width: 50px;
    height: 50px;
  }

  span {
    margin-top: 15px;
    font-size: 30px;
    color: var(--loader-text-color);
  }
}