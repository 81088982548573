.contentWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;

  img {
    width: 50px;
    height: 50px;
    margin-bottom: 8px;
  }

  .heading {
    font-size: 24px;
    font-weight: 500;
  }

  .subheading {
    color: var(--secondary-text);

    span {
      cursor: pointer;
      text-decoration: underline;
      color: var(--secondary-text);

      &:hover {
        color: var(--primary-text-color);
      }
    }
  }
}
