:root {
  --primary-color: #2170f4;
  --money-back-banner-bg: #f1f8ff;
  --primary-banner-bg: #f2f8ff;
  --secondary-text: #888888;
  --border-color: #dedede;
  --secondary-bg-color: #e8effd;
  --error-color: #ff4d4f;
  --gray-bg-color: #f9f9f9;
  --white: #ffffff;
  --primary-text-color: #333333;
  --light-sky-bg-color: #e6efff;
  --bg-color: #f7f7f7;
  --dark-border-color: #999999;
  --black: #000000;
  --green-color: #17b87e;
  --light-gray-color: #707070;
  --get-domain-bg: #f8fcff;
  --forest-green: #1d844a;
  --light-pastel-green: #ebf9ee;
  --ternary-text-color: #666666;
  --highlight-color: #fbaa4f;
  --tooltip-background-color: #1d2133;
  --sky: #e8effd;
  --secondary-border: #c5e2fa;
  --light-green-bg: #dcf7e7;
  --primary-gradient: linear-gradient(100deg, #1078ff 30.21%, #fe4ca2 98.31%);

  --app-height: 100vh;
}

@supports (height: 100dvh) {
  :root {
    --app-height: 100dvh;
  }
}
