.logo {
  width: 34px;
  height: auto;
  text-decoration: none;
  outline: none;
  cursor: pointer;

  img {
    width: 100%;
  }
}