.input-wrapper {
  margin-bottom: 30px;
  position: relative;
  width: 100%;

  label {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 8px;

    span {
      color: #777474;
      font-weight: 400;
      font-size: 14px;
      position: relative;
      bottom: 1px;
    }
  }

  .forgotPwd {
    position: absolute;
    right: 0;
    top: 0;
    font-weight: 400;
    font-size: 16px;
    text-decoration: underline;
    color: #888888;
    cursor: pointer;
  }

  .input {
    background: #ffffff;
    border: 1px solid #999999;
    border-radius: 8px;
    padding: 15px;
    font-size: 16px;
    width: 100%;

    &.mobile {
      display: inline-block;
    }

    &:focus {
      outline: 0;
      border: 1px solid #0066ff;
    }

    &.hasError {
      border: 1px solid var(--error-color);
    }
  }

  .select {
    display: inline-block;
    max-width: 110px;
    //padding: 6px 15px;
    padding: 0;
    margin-right: 15px;

    &:focus-within {
      outline: 0;
      border: 1px solid #0066ff;
    }
  }

  & > div {
    position: relative;
  }

  .togglePassword {
    width: 20px;
    aspect-ratio: 1;
    position: absolute;
    right: 20px;
    background: url("../../../assets/togglePassword.svg") center no-repeat;
    background-size: contain;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    height: 20px;
  }

  ::-webkit-credentials-auto-fill-button {
    visibility: hidden;
  }

  .emailDomain {
    display: inline-block;
    vertical-align: top;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 50%;
    background: #f0f0f0;
    border: 1px solid #999999;
    border-left: none;
    border-radius: 0 8px 8px 0;
    padding: 15px 20px;
    font-size: 16px;
    font-weight: 600;
    word-break: initial;
    white-space: nowrap;
  }
}

.error {
  color: var(--error-color);
  font-size: 14px;
  margin-top: 8px;
  word-break: break-word;
}

.singleVal {
  position: absolute;
}

.selectCaret {
  width: 0;
  height: 0;
  display: inline-block;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 6px solid #7d83a6;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

@media screen and (max-width: 640px) {
  .error {
    flex-direction: column;
  }

  .input-wrapper {
    input {
      font-size: 14px !important;
      padding: 10px !important;
    }

    .emailDomain {
      padding: 10px 5px;
      font-size: 14px;
      text-align: center;
    }
  }
}
