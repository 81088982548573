.discountText,
.successText {
  margin-bottom: 10px;
  font-size: 28px;
  font-weight: normal;
  b {
    font-size: inherit;
  }
}
.successSubText {
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: normal;
}

.continue {
  width: 100%;
}

@media screen and (max-width: 768px) {
  .successText,
  .discountText {
    font-size: 24px;
  }

  .continue {
    min-height: auto;
  }
}
