.header {
  width: 100%;
  max-width: 1366px;
  padding: 10px 30px 0;
  margin: 0 auto;
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
}

.wrapper {
  width: 96%;
  max-width: 800px;
  margin: 45px auto;

  @media screen and (max-width: 640px) {
    width: 100%;
    padding: 0 34px;
    box-sizing: border-box;
  }
}