.setup-dns {
  text-align: center;

  &.auto-login-flow {
    margin-top: 20vh;
  }

  img {
    width: 130px;
    height: auto;

    & + p {
      position: relative;
      padding-bottom: 45px;

      &::after {
        content: '';
        display: inline-block;
        width: 92px;
        height: 2px;
        background-color: var(--setup-dns-text-separator);
        position: absolute;
        bottom: -1px;
        left: calc(50% - 46px);
      }
    }
  }

  p {
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal
  }

  h1 {
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 48px auto 14px;

    & + p {
      line-height: 30px;
      margin: 0 auto 38px;
      max-width: 520px;

      span {
        font-size: inherit;
        font-weight: 700;
        text-transform: capitalize;
      }
    }
  }

  button {
    width: 100%;
    max-width: 329px;
    height: 56px;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
  }

  .actions {
    display: flex;
    flex-direction: column;
    align-items: center;

    button + span {
      margin-top: 18px;
    }
  }

  @media screen and (max-width: 640px) {
    img {
      & + p {
        padding-bottom: 30px;
      }
    }

    h1 {
      font-size: 28px;
      margin: 30px auto 8px;

      & + p {
        font-size: 16px;
        line-height: 24px;
      }
    }

    .actions {
      button + span {
        margin-top: 14px;
      }
    }
  }
}