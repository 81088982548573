@import "./styles/variables.scss";

div[data-plan-entropy] {
  --plan-color: #2170f4;
  --plan-color-hover: #174eab;

  &[data-plan-entropy="1"] {
    --plan-color: #4422A8;
    --plan-color-hover: #301876;
  }
  &[data-plan-entropy="2"] {
    --plan-color: #5A3DAF;
    --plan-color-hover: #3f2b7b;
  }
  &[data-plan-entropy="3"] {
    --plan-color: #1353BD;
    --plan-color-hover: #0d3a84;
  }
}

.magic-dots.slick-dots li.slick-active button:before {
  color: #888;
}

.feat-slider-container .slick-track {
  display: flex;
  align-items: center;
}

.visibilityHidden {
  visibility: hidden;
}

// Getting added in user agent style sheet by default and messes up styling
:focus-visible {
  outline: none;
}